import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardLandingPage } from "./pages/DashboardLandingPage";

export default function BasePageLanding() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
      <ContentRoute exact path="/" component={DashboardLandingPage} />
        <Redirect from="*" to="/" />
      </Switch>
    </Suspense>
  );
}
