import { createSlice } from "@reduxjs/toolkit";

const initialOrganizationProfileState = {
    // listLoading: false,
    actionsLoading: false,
    viewProfile: false, //
    lastError: null
};
export const callTypes = {
    // list: "list",
    action: "action"
};

export const organizationProfileSlice = createSlice({
    name: "organizationProfile",
    initialState: initialOrganizationProfileState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                // state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                // state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // 
        showOrganizationProfile: (state, action) => {
            state.actionsLoading = false;
            state.viewProfile = action.payload.viewProfile;
            state.error = null;
        },
    }
});
