/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";

export function TilesWidget10({ 
  className, 
  baseColor = "primary",
  widgetHeight = "175px" 
}) {
  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{ height: 350 }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="row">
            <div className="col-xl-2">
              <div className="symbol symbol-75 symbol-light mr-3 flex-shrink-0">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-primary">
                    <SVG
                      className=" h-50"
                      style={{ width: 40, height: 40 }}
                      src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                    ></SVG>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-10">
              <div className="mr-2">
                <h3 className={`text-inverse-${baseColor} font-weight-bolder`}>Identity Provider</h3>
                <div className="text-dark-50 font-size-lg mt-2">
                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`text-inverse-${baseColor} font-weight-bold mt-1`}>
                          We offer friendly interfaces for managing all types of users and privileges you need for your Organization
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`text-inverse-${baseColor} font-weight-bold mt-1`}>
                          Using the standards <strong style={{ fontSize: 16 }}>SAML</strong> and <strong style={{ fontSize: 16 }}>oauth2</strong>, you can implement this solution as Authentication and Authorization provider very quickly in all your systems
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`text-inverse-${baseColor} font-weight-bold mt-1`}>
                          You can enrich the profiles with relevant info, and define the scope and access to each level
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`text-inverse-${baseColor} font-weight-bold mt-1`}>
                          You can define what actions require a higher level of security and require Multi-Factor Authentication
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
