import React from "react";
import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Grid } from '@material-ui/core';

export default function CardAboutOrganization({ data }) {

    return (
        <div class="card card-custom gutter-b">
            <div class="ribbon ribbon-top ribbon-ver">
                <div class="ribbon-target bg-primary" style={{ top: 2, right: 20 }}>
                    <i class="fa fa-star text-white"></i>
                </div>

            </div>
            <div class="card-body">
                <h3 class="card-title">
                    About
                </h3>
                <Typography variant="body2" color="textSecondary" component="p">
                    <Grid container spacing={1}>
                        <Grid item>
                            <InfoIcon style={{ color: "#6993FF" }} />
                        </Grid>
                        <Grid item xs={10}>
                            <span>
                                The Adjusters of Adjuster X come from a military back ground.
                                In a prior life we were taught to live by a simple code and we carried this code over to Catastrophic Insurance Adjusting.<br /><br />
                                <strong>Key Values</strong><br />
                                <CheckCircleOutlineIcon style={{ color: "#6993FF" }} />Military background<br />
                                <CheckCircleOutlineIcon style={{ color: "#6993FF" }} />Skilled planning out complex operations<br />
                                <CheckCircleOutlineIcon style={{ color: "#6993FF" }} />Honesty and clear communication. These are important in any field of work, though in claims, service it is paramount.
                        </span>
                        </Grid>
                    </Grid>
                </Typography>

                <br />

                <Typography variant="body2" color="textSecondary" component="p">
                    <Grid container spacing={1}>
                        <Grid item>
                            <PublicIcon style={{ color: "#6993FF" }} />
                        </Grid>
                        <Grid item xs={10}>
                            <a href="https://adjusterx.com">https://adjusterx.com</a>
                        </Grid>
                    </Grid>
                </Typography>
            </div>
        </div>
    );
}
