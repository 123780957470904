/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { makeStyles, Paper, InputBase, Divider, IconButton, Snackbar } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

export function TilesWidgetRocket({ className, widgetHeight = "175px" }) {
  const classes = useStyles();
  const backgroundImageUrl = toAbsoluteUrl("/media/bg/Rocket-Background.png");

  const [email, setEmail] = React.useState("");
  const vertical = "top";
  const horizontal = "center";
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const subscribe = () => {
    axios.put(`crm/leads/newsletter`, { email }).then((res) => {
      // console.log(res.data);
      if (res.data.success === true) {
        setErrorMessage("Signup !!!");
        openMessage();

      } else {
        setErrorMessage(res.data.errors[0].message);
        openMessage();
      }
    });
  }

  const openMessage = () => {
    setOpen(true);
  };

  function closeMessage() {
    setOpen(false);
  }

  return (
    <>
      <div
        className={`card card-custom bgi-no-repeat ${className}`}
        style={{ height: widgetHeight, backgroundPosition: "100% 100%", backgroundSize: "100% 100%", backgroundImage: `url("${backgroundImageUrl}")` }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center">
          <div>
            <h3 className="text-white font-weight-bolder line-height-lg mb-5">Will Launch Soon<br />Signup to receive info on the launching date and upcoming deals</h3>
            <form>
              <Paper className={classes.root}>
                <IconButton className={classes.iconButton} aria-label="Menu">
                  <EmailIcon />
                </IconButton>
                <InputBase
                  className={classes.input}
                  placeholder="Email"
                  inputProps={{ 'aria-label': 'Email' }}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {/*<IconButton className={classes.iconButton} aria-label="Search">
              <SearchIcon />
            </IconButton>*/}
                <Divider className={classes.divider} />
                <IconButton color="secondary" className={classes.iconButton} aria-label="Send" onClick={subscribe}>
                  <SendIcon />
                </IconButton>
              </Paper>
            </form>
          </div>
        </div>
        {/* end::Body */}
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={closeMessage}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{errorMessage}</span>}
      />
    </>
  );
}
