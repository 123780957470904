import axios from "axios";
import gpauth from "../../../../redux/interceptors/gpauth";

import {
  APP
} from "../../../../config";

export const USER_PROFILE_URL = "users/profile";
export const SEND_VERIFICATION_CODE_URL = "https://api.auth.globalprofile.com/auth/otp/send";
export const VALIDATE_VERIFICATION_CODE_URL = "https://api.auth.globalprofile.com/auth/otp/validate";

const { gid, publicKey } = APP;

export function getAppToken() {
  return gpauth.post(`auth/apps/login`, { gid, publicKey });
}

export async function getUserByToken() {
  let res = await axios.get(`${USER_PROFILE_URL}/personal`);
  return res;
}

export async function sendOTP(userId, contact, type) {
  try {
    const response = await axios.post(SEND_VERIFICATION_CODE_URL, { userId, contact, type });
    return response;
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
}

export async function validateOTP(userId, otpId, otpCode) {
  try {
    const response = await axios.post(VALIDATE_VERIFICATION_CODE_URL, { userId, otpId, otpCode });
    return response;
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
}

export async function validateOTPWithPassword(userId, otpId, otpCode, password) {
  try {
    const response = await axios.post(VALIDATE_VERIFICATION_CODE_URL, { userId, otpId, otpCode, password });
    return response;
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
}
