/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";

export function TilesWidgetMarketplace({ className }) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: 385 }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="row">
            <div className="col-xl-2">
              <div className="symbol symbol-75 symbol-light mr-3 flex-shrink-0">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-primary">
                    <SVG
                      className=" h-50"
                      style={{ width: 45, height: 45 }}
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}
                    ></SVG>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-10">
              <div className="mr-2">
                <h3 className={`font-weight-bolder`}>Integrated Marketplace</h3>
                <div className="text-dark-50 font-size-lg mt-2">
                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          We are committed to help Service Companies to be on Market as quickly and simply as possible, but allowing them to grow and personalize their business models
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          Supporting SaaS, Subscription Business, Pay by Transaction and Hybrid models
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          You can assign the Roles and Privileges to each Subscription Plan, Add-on or service that you configure. Allowing us to assign the correct permissions to each user automatically
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          You can configure webhooks and integrate the e-commerce module to trigger some action in your systems when some new order is placed, for example
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          Also, you’ll have apis available to update back the progress of orders and keep your customers informed
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
