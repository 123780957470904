/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { makeStyles, Paper, InputBase, Divider, IconButton } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
// import SendIcon from '@material-ui/icons/Send';
// import EmailIcon from '@material-ui/icons/Email';

/*const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});*/

export function TilesWidgetGrowth({ className, widgetHeight = "175px" }) {
  // const classes = useStyles();

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="mr-2">
            <h3 className="font-weight-bolder">Getting started is simple, GROWTH is unlimited</h3>
            <div className="text-dark-50 font-size-lg mt-2">
              We will never stop your growth, we offer dev tools so you can customize how much you need in external systems.
              Everything that you can see and manage in our platform, you will have the same apis available for managing it from your own systems in house as needed. You have the control of your data
            </div>
          </div>

          {/*<a href="#" className="btn btn-primary font-weight-bold py-3 px-6 mt-3">
            Subscribe
          </a>*/}
          {/*<Paper className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="Menu">
              <EmailIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Email"
              inputProps={{ 'aria-label': 'Email' }}
            />*/}
          {/*<IconButton className={classes.iconButton} aria-label="Search">
              <SearchIcon />
            </IconButton>*/}
          {/*<Divider className={classes.divider} />
            <IconButton color="secondary" className={classes.iconButton} aria-label="Send">
              <SendIcon />
            </IconButton>
          </Paper>*/}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
