import React, { Component } from "react";
import * as authCrud from "../_redux/authCrud";
import {
    AUTH_URL
  } from "../../../../config";

export class RedirectToGPLogin extends Component {
    constructor(props) {
        super();
        this.state = { ...props };
    }
    componentWillMount() {
        authCrud.getAppToken().then((res) => {
            // console.log(res);
            const appToken = res.data.content;
            window.location.href = `${AUTH_URL}/auth/login/${appToken}`;
        })
    }
    render() {
        return (<section>Redirecting...</section>);
    }
}

export default RedirectToGPLogin;
