import React from "react";
import Carousel from 'react-material-ui-carousel'
import { Button, IconButton, Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share';
import {
    FacebookShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    TwitterShareButton,
    FacebookIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon,
    TwitterIcon
} from "react-share";

const options = ["Facebook", "Whatsapp", "Linkedin", "Email", "Twitter"];
const ITEM_HEIGHT = 60;

export default function CardAvatarOrganization({ data }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function share(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleCloseShareButton() {
        setAnchorEl(null);
    }

    const menuItemClicked = (ev) => {
        setAnchorEl(null);
    };

    // only for test purpose
    var items = [
        {
            url: "https://adjusterx.com/wp-content/uploads/2021/02/home.png",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            url: "https://adjusterx.com/wp-content/uploads/2021/01/Hail.png",
            description: "Hello World!"
        }
    ];

    return (
        <div>
            <Carousel autoPlay={true} animation='slide' indicators={false}>
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>

            <div class="img-fluid d-flex align-items-center" style={{ marginLeft: 45 }}>
                <div class="flex-shrink-0 mr-4 symbol symbol-100">
                    <img src="https://route.test.adjusterx.com/media/logos/logo-dark.png" alt="" />
                </div>

                <div class="d-flex flex-column mr-auto">
                    <a href="#" class="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-0">Adjuster X</a>
                    <span class="text-muted font-weight-bold">Creates Limitless possibilities</span>
                </div>

                <Button size="small" color="secondary" onClick={share} style={{ marginRight: 55 }}>
                    <IconButton aria-label="Share" style={{ color: '#6993FF' }} onClick={share}>
                        <ShareIcon />
                    </IconButton>
                            Share
                        </Button>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleCloseShareButton}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 185,
                        },
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option}
                            onClick={menuItemClicked}
                        >
                            {option === 'Facebook' && (
                                <ListItemIcon>
                                    <FacebookShareButton url={`https://adjusterx.com`}>
                                        <FacebookIcon size={32} round={true} /><span style={{ marginLeft: 15 }}>{option}</span>
                                    </FacebookShareButton>
                                </ListItemIcon>
                            )}
                            {option === 'Whatsapp' && (
                                <ListItemIcon>
                                    <WhatsappShareButton url={`https://adjusterx.com`}>
                                        <WhatsappIcon size={32} round={true} /><span style={{ marginLeft: 15 }}>{option}</span>
                                    </WhatsappShareButton>
                                </ListItemIcon>
                            )}
                            {option === 'Linkedin' && (
                                <ListItemIcon>
                                    <LinkedinShareButton url={`https://adjusterx.com`}>
                                        <LinkedinIcon size={32} round={true} /><span style={{ marginLeft: 15 }}>{option}</span>
                                    </LinkedinShareButton>
                                </ListItemIcon>
                            )}
                            {option === 'Email' && (
                                <ListItemIcon>
                                    <EmailShareButton url={`https://adjusterx.com`}>
                                        <EmailIcon size={32} round={true} /><span style={{ marginLeft: 15 }}>{option}</span>
                                    </EmailShareButton>
                                </ListItemIcon>
                            )}
                            {option === 'Twitter' && (
                                <ListItemIcon>
                                    <TwitterShareButton url={`https://adjusterx.com`}>
                                        <TwitterIcon size={32} round={true} /><span style={{ marginLeft: 15 }}>{option}</span>
                                    </TwitterShareButton>
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </div>
    );
}

function Item(props) {
    return (
        <img src={props.item.url} class="custom-img-fluid" alt="" />
    )
}
