import {createSlice} from "@reduxjs/toolkit";

const initialApplicationsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    applicationForEdit: undefined,
    lastError: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const applicationsSlice = createSlice({
    name: "applications",
    initialState: initialApplicationsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
              state.listLoading = false;
            } else {
              state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getApplicationById
        applicationFetched: (state, action) => {
            state.actionsLoading = false;
            state.applicationForEdit = action.payload.applicationForEdit;
            state.error = null;
        },
        // findApplications
        applicationsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createApplication
        applicationCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.application);
        },
        // updateApplication
        applicationUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
            if (entity.applicationId === action.payload.application.applicationId) {
                return action.payload.application;
            }
            return entity;
            });
        },
        // deleteApplication
        applicationDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.applicationId !== action.payload.id);
      },
    }
})
