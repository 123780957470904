export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { accessToken }
      } = store.getState();

      if (accessToken) {
        config.headers["Authorization-Token"] = `${accessToken}`;
      }

      config.baseURL= "https://api.globalprofile.com/";
      return config;
    },
    err => Promise.reject(err)
  );
}
