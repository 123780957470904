import React from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
  TilesWidget13
} from "../widgets";
import { Switch } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import CardDashboardOrganization from "../cards/CardDashboardOrganization";

export function Demo1Dashboard() {
  const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <>
      {/*begin::GP Publicity */}
      {/*<div className="row">
        <div className="col-lg-12">
          <TilesWidget13 className="gutter-b" widgetHeight="185px" />
        </div>
      </div>*/}
      {/*end::GP Publicity */}

      {/*begin::Subscriptions */}
      {/*<div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-shrink-0 mr-7">
                  <div class="symbol symbol-50 symbol-lg-100 symbol-light-danger">
                    <span class="font-size-h3 symbol-label font-weight-boldest">BP</span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="d-flex align-items-center justify-content-between flex-wrap mt-2">
                    <div class="mr-3">
                      <a href="#" class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">Basic Plan
														<i class="flaticon2-correct text-success icon-md ml-2"></i></a>
                      <div class="d-flex flex-wrap my-2">
                        <a href="#" class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                          <span className="svg-icon menu-icon mr-4 text-muted font-weight-bold">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                            />
                          </span>
                          Global Profile
                        </a>
                      </div>
                    </div>
                    <div class="my-lg-0 my-1">
                      <a href="#" class="btn btn-sm btn-primary font-weight-bolder text-uppercase mr-2">Switch Plan</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center flex-wrap justify-content-between">
                    <div class="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
                      <div class="flex-grow-1 font-weight-bold text-dark-50 mr-5">Description Plan</div>
                    </div>
                    <div class="d-flex mt-4 mt-sm-0">

                    </div>
                  </div>
                </div>
              </div>
              <div class="separator separator-solid my-3"></div>
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span class="mr-4">
                    <i class="flaticon-event-calendar-symbol icon-2x text-muted font-weight-bold"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                    <span class="font-weight-bolder font-size-sm">Expires On</span>
                    <span class="font-weight-bolder font-size-h5">
                      <span class="text-dark-50 font-weight-bold">Wed </span>Jun 25, 2025</span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span class="mr-4">
                    <i class="flaticon-bell icon-2x text-muted font-weight-bold"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                    <span class="font-weight-bolder font-size-sm">Upcoming Payment</span>
                    <span class="font-weight-bolder font-size-h5">
                      <span class="text-dark-50 font-weight-bold">Wed</span>Jun 25, 2025</span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span class="mr-4">
                    <i class="flaticon-refresh icon-2x text-muted font-weight-bold"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                    <span class="font-weight-bolder font-size-sm">Autopay OFF
                    <Switch
                        checked={state.checkedA}
                        onChange={handleChange('checkedA')}
                        value="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="svg-icon svg-icon-3x menu-icon mr-4 text-muted font-weight-bold">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}
                    />
                  </span>
                  <div class="d-flex flex-column flex-lg-fill">
                    <span class="text-dark-75 font-weight-bolder font-size-sm">Card</span>
                    <a href="#" class="text-primary font-weight-bolder">ending on 1234</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>*/}
      {/*end::Subscriptions */}

      {/*begin::Marketplace  */}
      <div className="row">
        <CardDashboardOrganization />
      </div>
      {/*end::Marketplace  */}
    </>
  );
}
