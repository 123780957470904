import { createSlice } from "@reduxjs/toolkit";

const initialPaymentsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    paymentForEdit: undefined,
    lastError: null,
    failed: false
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const paymentsSlice = createSlice({
    name: "payments",
    initialState: initialPaymentsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getPaymentById
        paymentFetched: (state, action) => {
            state.actionsLoading = false;
            state.paymentForEdit = action.payload.paymentForEdit;
            state.error = null;
        },
        // findPayments
        paymentsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.failed = false;
        },
        //
        failed: (state, action) => {
            const { failed } = action.payload;
            state.actionsLoading = false;
            state.failed = failed;
        }
    }
});
