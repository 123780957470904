import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { rolesSlice } from "../app/modules/Security/_redux/roles/rolesSlice";
import { applicationsSlice } from "../app/modules/SaaS/_redux/applications/applicationsSlice";
import { membersSlice } from "../app/modules/Security/_redux/roles/members/membersSlice";
import { privilegesSlice } from "../app/modules/Security/_redux/privileges/privilegesSlice";
import { scopesSlice } from "../app/modules/Security/_redux/scopes/scopesSlice";
import { usersSlice } from "../app/modules/UserProfile/_redux/usersSlice";
import { paymentsSlice } from "../app/modules/Vendors/_redux/payments/paymentsSlice";
import { organizationProfileSlice } from "../app/modules/OrganizationProfile/_redux/organizationProfileSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  roles: rolesSlice.reducer,
  applications: applicationsSlice.reducer,
  members: membersSlice.reducer,
  privileges: privilegesSlice.reducer,
  scopes: scopesSlice.reducer,
  users: usersSlice.reducer,
  payments: paymentsSlice.reducer,
  organizationProfile: organizationProfileSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
