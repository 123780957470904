import React, { useEffect, useState } from "react";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CardAboutOrganization from "../../_metronic/_partials/cards/CardAboutOrganization";
import CardAvatarOrganization from "../../_metronic/_partials/cards/CardAvatarOrganization";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export const PublicOrganizationProfile = ({
    history,
    match: {
        params: { nameapp },
    },
}) => {
    const classes = useStyles();

    const [tab, setTab] = useState("overview");

    return (
        <>
            <div class="card card-custom gutter-b">
                {/*<div class="card-header">
                    <div class="card-title">
                        
                    </div>
                    <div class="card-toolbar">
                        
                    </div>
                </div>*/}
                <CardAvatarOrganization />

                {/*<div class="separator separator-solid separator-white opacity-20"></div>*/}

                <div class="card-body">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item" onClick={() => setTab("overview")}>
                            <a
                                className={`nav-link ${tab === "overview" && "active"}`}
                                data-toggle="tab"
                                role="tab"
                                aria-selected={(tab === "overview").toString()}
                            >
                                Overview
                            </a>
                        </li>
                        {" "}
                        <li className="nav-item" onClick={() => setTab("products")}>
                            <a
                                className={`nav-link ${tab === "products" && "active"}`}
                                data-toggle="tab"
                                role="button"
                                aria-selected={(tab === "products").toString()}
                            >
                                Products
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => setTab("bills-payments")}>
                            <a
                                className={`nav-link ${tab === "bills-payments" && "active"}`}
                                data-toggle="tab"
                                role="tab"
                                aria-selected={(tab === "bills-payments").toString()}
                            >
                                {`Bills & Payments`}
                            </a>
                        </li>
                    </ul>
                    <div className="mt-5">
                        {tab === "overview" && (
                            <div className="row">
                                <div className="col-lg-6"><CardAboutOrganization /></div>
                                <div className="col-lg-6"></div>
                            </div>
                        )}
                        {tab === "products" && (
                            <></>
                        )}
                        {tab === "bills-payments" && (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
