import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";

function Login(props) {
  const { accessToken } = props;

  React.useEffect(() => {
    if (accessToken) {
      props.login(accessToken);
    }
  },[accessToken]);

  return (
    <>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
