import React from "react";
import { makeStyles } from '@material-ui/core';
// import clsx from 'clsx';
import { Card, CardHeader, CardMedia, CardContent, CardActions, IconButton, Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import {
    FacebookShareButton,
    PinterestShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    TwitterShareButton,
    FacebookIcon,
    PinterestIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon,
    TwitterIcon
} from "react-share";
import { useDispatch } from "react-redux";
import * as actionsOrganizationProfile from "../../../app/modules/OrganizationProfile/_redux/organizationProfileActions";

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: "#000",
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
    imgAvatar: {
        margin: 10,
        width: 65,
        height: 'auto',
    },
}));

export default function CardDashboardOrganization({ data }) {
    const classes = useStyles();
    const history = useHistory();

    const [showShareOptions, setShowShareOptions] = React.useState(false);

    const dispatch = useDispatch();
    const view = (nameapp) => {
        dispatch(actionsOrganizationProfile.showOrganizationProfile(true));
        history.push(`/${nameapp}`);
    }

    const shareOptions = () => {
        setShowShareOptions(!showShareOptions);
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    /*<Avatar aria-label="Recipe" className={classes.avatar}>
                        ?
                    </Avatar>*/
                    <img alt="Adjuster X" src="https://route.test.adjusterx.com/media/logos/logo-dark.png" className={classes.imgAvatar} />
                }
                title={<strong>Adjuster X</strong>}
                subheader="Insurances"
            />
            <CardMedia
                className={classes.media}
                image="https://adjusterx.com/wp-content/uploads/2021/04/Becoming-an-Adjuster-6.jpg"
            // title="?"
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    The Adjusters of Adjuster X come from a military back ground.
                    In a prior life we were taught to live by a simple code and we carried this code over to Catastrophic Insurance Adjusting.
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="Share" onClick={shareOptions} style={{ color: showShareOptions ? '#6993FF' : '' }}>
                    <ShareIcon />
                </IconButton>
                {showShareOptions && (
                    <Grid container justify="center" spacing={1} style={{ marginLeft: -95 }}>
                        <Grid item xs={1}>
                            <FacebookShareButton url={`https://adjusterx.com`}>
                                <FacebookIcon size={24} round={true} />
                            </FacebookShareButton>
                        </Grid>
                        <Grid item xs={1}>
                            <WhatsappShareButton url={`https://adjusterx.com`}>
                                <WhatsappIcon size={24} round={true} />
                            </WhatsappShareButton>
                        </Grid>
                        <Grid item xs={1}>
                            <LinkedinShareButton url={`https://adjusterx.com`}>
                                <LinkedinIcon size={24} round={true} />
                            </LinkedinShareButton>
                        </Grid>
                        <Grid item xs={1}>
                            <EmailShareButton url={`https://adjusterx.com`}>
                                <EmailIcon size={24} round={true} />
                            </EmailShareButton>
                        </Grid>
                        <Grid item xs={1}>
                            <TwitterShareButton url={`https://adjusterx.com`}>
                                <TwitterIcon size={24} round={true} />
                            </TwitterShareButton>
                        </Grid>
                    </Grid>
                )}
                <Button size="small" color="secondary" style={{ marginLeft: 'auto' }} onClick={() => view('adjuster-x')}>
                    View
                </Button>
            </CardActions>
        </Card>
    );
}
