import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import RedirectToGPLogin from "./RedirectToGPLogin";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="gp_login"
        >
          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                {/* Internal URLs */}
                <Route
                  path="/auth/login/:accessToken"
                >
                  {({
                    // history, 
                    match }) => (
                    <Login
                      accessToken={match && match.params.accessToken}
                    />
                  )}
                </Route>
                <Route
                  path="/auth/login" component={RedirectToGPLogin}
                />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                {<Redirect to="/auth/login" />}
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
