/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";

export function TilesWidgetBrand({ className }) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: 385 }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="row">
            <div className="col-xl-2">
              <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-primary">
                    <SVG
                      className=" h-50"
                      style={{ width: 25, height: 25 }}
                      src={toAbsoluteUrl("/media/svg/icons/Code/Puzzle.svg")}
                    ></SVG>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-10">
              <div className="mr-2">
                <h3 className={`font-weight-bolder`}>Boost your brand</h3>
                <div className="text-dark-50 font-size-lg mt-2">
                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          Your brand matters to us, you can define it at the Organization Level, and then customize it for each application
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          Our Login and Sign Up related pages will use your brand when accessing to one of your systems/applications
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}

                  {/* begin::Item */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center mr-2">
                      <div>
                        <div className={`font-weight-bold mt-1`}>
                          Also will be customized our communication with users and the Invoices and other reports
                  </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
