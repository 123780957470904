/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>

        {/*begin::Account & Settings*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/account-settings"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/account-settings">
            <span className="menu-text">{"Account & Settings"}</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/user-profile")}`}>
                <NavLink className="menu-link" to="/user-profile">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    />
                  </span>
                  <span className="menu-text">My Profile</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/security"
                )}`}
                data-menu-toggle="hover"
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/security">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Shield-protected.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Security</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div
                  className={`menu-submenu menu-submenu-classic menu-submenu-right`}
                >
                  <ul className="menu-subnav">
                    {/* begin::My Transactions */}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/security/transactions"
                      )}`}
                    >
                      <NavLink className="menu-link" to="/security/transactions">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">My Transactions</span>
                      </NavLink>
                    </li>
                    {/* end::My Transactions */}

                    {/* begin::Privacy Settings */}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/security/privacy-settings"
                      )}`}
                    >
                      <NavLink className="menu-link" to="/security/privacy-settings">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Privacy Settings</span>
                      </NavLink>
                    </li>
                    {/* end::Privacy Settings */}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
        {/*end::Account & Settings*/}

        {/*  */}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/vendors"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/vendors">
            <span className="menu-text">{"Vendors"}</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/vendors/organizations")}`}>
                <NavLink className="menu-link" to="/vendors/organizations">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                    />
                  </span>
                  <span className="menu-text">Organizations</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/vendors/services")}`}>
                <NavLink className="menu-link" to="/vendors/services">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}
                    />
                  </span>
                  <span className="menu-text">My Services</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/vendors/subscriptions")}`}>
                <NavLink className="menu-link" to="/vendors/subscriptions">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-check.svg")}
                    />
                  </span>
                  <span className="menu-text">My Subscriptions</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/vendors/orders")}`}>
                <NavLink className="menu-link" to="/vendors/orders">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}
                    />
                  </span>
                  <span className="menu-text">My Orders</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/vendors/payments")}`}>
                <NavLink className="menu-link" to="/vendors/payments">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
                    />
                  </span>
                  <span className="menu-text">{"My Bills & Payments"}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/*  */}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
