import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { PublicOrganizationProfile } from "./pages/PublicOrganizationProfile";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const Vendorpage = lazy(() =>
  import("./modules/Vendors/VendorsPage")
);

const Security = lazy(() => import("./modules/Security/pages/iSecurityPage"));
const SaaSPage = lazy(() => import("./modules/SaaS/pages/SaaSPage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/vendors" component={Vendorpage} />
        <Route path="/security" component={Security} />
        <Route path="/saas" component={SaaSPage} />
        <ContentRoute path="/:nameapp" component={PublicOrganizationProfile} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
